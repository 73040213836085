import React, { createContext, useReducer, useContext } from 'react';
//import useMediaQuery from '@material-ui/core/useMediaQuery';
import AppReducer from './AppReducer';
import { SetPlayers, SetZones, SetPlaylists } from './mzap'
import { localStorageGet } from '../utils/localStorage';
import log from 'loglevel';

import {
  GetPlayers, GetZones, GetAllPlaylists, UpdatePlaylists, CreatePlayer, DeletePlayer,
  PlayerPause, PlayerPlayNext, PlayerPlay, PlayerPlayPrev, SetPlayerMute, SetPlayerVolumeDb, GetPlayerPlaylist,
  PlayerRemoveZone, PlayerAddZone, SetZoneMute, RenameZone, RenamePlayer, SetPlayerMode, PlayerPlayTrackByIndex,
} from '../api';

const initialAppState = { //
  darkMode: localStorageGet('darkMode', false), //JSON.parse(localStorage.getItem('darkMode')) || false, // Overridden by useMediaQuery('(prefers-color-scheme: dark)') in AppStore
  error: '',
  menuVisible: false,
  zoneNameVisible: localStorageGet('zoneNameVisible', true), //JSON.parse(localStorage.getItem('zoneNameVisible')) || true,
  zonesVisible: localStorageGet('zonesVisible', true), //JSON.parse(localStorage.getItem('zonesVisible')) || true,

  baseUrl: '',//'http://localhost:8080/MZAP/API/v1/rest/',
  login: '',
  password: '',
  recentConnections: JSON.parse(localStorage.getItem('recentConnections')) || [],//  ['localhost1:8080', 'localhost2:8080', 'localhost3:8080'],

  players: [],
  zones: [],
  playlists: [],

};

/**
 * Instance of React Context for global AppStore
 *
 * import {AppContext} from './store'
 * ...
 * const [state, dispatch] = useContext(AppContext);
 *
 * OR
 *
 * import {useAppStore} from './store'
 * ...
 * const [state, dispatch] = useAppStore();
 *
 */
const AppContext = createContext(initialAppState);

/**
 * Main global Store as HOC with React Context API
 *
 * import AppStore from './store'
 * ...
 * <AppStore>
 *  <App/>
 * </AppStore>
 */
const AppStore = ({ children }) => {
  // const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  // const [state, dispatch] = useReducer(AppReducer, { ...initialAppState, darkMode: prefersDarkMode });
  const [state, dispatch] = useReducer(AppReducer, initialAppState);

  async function getZones() {
    return await GetZones().then((res) => {
      dispatch(SetZones(res))
      getPlayers()
      getAllPlaylists()
      return res
    })
  }

  let prevPlayers = [];

  async function getPlayers() {
    const players = await GetPlayers()
    if (JSON.stringify(prevPlayers) !== JSON.stringify(players)) {
      log.debug('players changed')
      dispatch(SetPlayers(players))
      prevPlayers = players
    }
    return players;
  }

  async function getAllPlaylists() {
    const playlists = await GetAllPlaylists()
    dispatch(SetPlaylists(playlists))
    return playlists;
  }

  async function updatePlaylists() {
    const playlists = await UpdatePlaylists()
    dispatch(SetPlaylists(playlists))
    return playlists;
  }

  async function playerPause(id) {
    await PlayerPause(id).then(() => {
      getPlayers();
    })
  }

  async function playerPlay(id) {
    await PlayerPlay(id).then(() => {
      getPlayers();
    })
  }

  async function playerPlayNext(id) {
    await PlayerPlayNext(id).then(() => {
      getPlayers();
    })
  }

  async function playerPlayPrev(id) {
    await PlayerPlayPrev(id).then(() => {
      getPlayers();
    })
  }

  async function setPlayerMute(id, mute) {
    await SetPlayerMute(id, mute).then(() => {
      getPlayers();
    })
  }

  async function setPlayerVolumeDb(id, volume) {
    await SetPlayerVolumeDb(id, volume).then(() => {
      //getPlayers();
    })
  }

  async function playerAddZone(id, zoneId) {
    await PlayerAddZone(id, zoneId).then(() => {
      getPlayers();
    })
  }

  async function playerRemoveZone(id, zoneId) {
    await PlayerRemoveZone(id, zoneId).then(() => {
      getPlayers();
    })
  }

  async function renamePlayer(id, name) {
    return await RenamePlayer(id, name).then(res => {
      getPlayers();
      return res;
    })
  }

  //zones
  async function setZoneMute(id, mute) {
    await SetZoneMute(id, mute).then(() => {
      getZones();
    })
  }

  async function renameZone(id, name) {
    return await RenameZone(id, name).then(res => {
      getZones();
      return res;
    })
  }

  async function setPlayerMode(id, mode) {
    return await SetPlayerMode(id, mode).then(res => {
      getPlayers();
      return res;
    })
  }

  async function playerPlayTrackByIndex(id, index) {
    return await PlayerPlayTrackByIndex(id, index).then(res => {
      getPlayers()
      return res
    })
  }


  async function getPlayerPlaylist(id) {
    return await GetPlayerPlaylist(id)
  }

  async function createPlayer(name) {
    return await CreatePlayer(name).then(res => {
      getPlayers()
      return res
    })
  }

  async function deletePlayer(id) {
    return await DeletePlayer(id).then(res => {
      getPlayers()
      return res
    })
  }

  const result = {
    state, dispatch,
    getPlayers, getZones, getAllPlaylists, updatePlaylists, createPlayer, deletePlayer,
    playerPause, playerPlay, playerPlayNext, playerPlayPrev, setPlayerMute, setPlayerVolumeDb, renamePlayer,
    playerAddZone, playerRemoveZone, setZoneMute, renameZone, setPlayerMode, playerPlayTrackByIndex, getPlayerPlaylist,
  }

  return <AppContext.Provider value={result}>{children}</AppContext.Provider>;
};

/**
 * Hook to use the AppStore in functional components
 */
const useAppStore = () => useContext(AppContext);

/**
 * HOC to inject the ApStore to functional or class component
 */
const withAppStore = (Component) => (props) => {
  return <Component {...props} store={useAppStore()} />;
};

export { AppStore as default, AppStore, AppContext, useAppStore, withAppStore };

let API = {};

/**
 * Sets global API instance
 * Used in Main, Login views
 */
export function setGlobalApi(apiInstance) {
  API = apiInstance;
}

export * from './player';
export * from './zones';
export * from './general';
// export * from './settings';
// export * from './other';
export {API, API as default};
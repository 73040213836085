import API from '.';
import log from '../utils/log';

export async function GetPlayers() {
  try {
    const res = await API.get('/GetPlayers');
    const result = res.data;
    log.warn('GetPlayers() - ', result);
    return result;
  } catch (error) {
    log.error('GetPlayers() - ', error);
    return {};
  }
}

// export async function GetPlayers1(dispatch) {
//   try {
//     const res = await API.get('/GetPlayers');
//     const result = res.data;
//     log.warn('GetPlayers() - ', result);
    
//     dispatch(SetPlayers(result));

//     return result;
//   } catch (error) {
//     log.error('GetPlayers() - ', error);
//     return {};
//   }
// }

export async function GetPlayer(id) {
  try {
    const res = await API.get('/GetPlayerById', {
      params: {
        playerId: id
      }
    });
    const result = res.data;
    log.warn('GetPlayerById() - ', result);
    return result;
  } catch (error) {
    log.error('GetPlayerById() - ', error);
    return {};
  }
}

export async function PlayerPause(id) {
  try {
    const res = await API.get('/PlayerPause', {
      params: {
        playerId: id
      }
    });
    const result = res.data;
    log.warn('PlayerPause() - ', result);
    return result;
  } catch (error) {
    log.error('PlayerPause() - ', error);
    return {};
  }
}

export async function PlayerPlay(id) {
  try {
    const res = await API.get('/PlayerPlay', {
      params: {
        playerId: id
      }
    });
    const result = res.data;
    log.warn('PlayerPlay() - ', result);
    return result;
  } catch (error) {
    log.error('PlayerPlay() - ', error);
    return {};
  }
}

export async function PlayerPlayNext(id) {
  try {
    const res = await API.get('/PlayerPlayNext', {
      params: {
        playerId: id
      }
    });
    const result = res.data;
    log.warn('PlayerPlayNext() - ', result);
    return result;
  } catch (error) {
    log.error('PlayerPlayNext() - ', error);
    return {};
  }
}

export async function PlayerPlayPrev(id) {
  try {
    const res = await API.get('/PlayerPlayPrev', {
      params: {
        playerId: id
      }
    });
    const result = res.data;
    log.warn('PlayerPlayPrev() - ', result);
    return result;
  } catch (error) {
    log.error('PlayerPlayPrev() - ', error);
    return {};
  }
}

export async function PlayerStop(id) {
  try {
    const res = await API.get('/PlayerStop', {
      params: {
        playerId: id
      }
    });
    const result = res.data;
    log.warn('PlayerStop() - ', result);
    return result;
  } catch (error) {
    log.error('PlayerStop() - ', error);
    return {};
  }
}

export async function SetPlayerVolumeDb(id, volume) {
  try {
    const res = await API.get('/SetPlayerVolumeDb', {
      params: {
        playerId: id,
        volumeDb: volume
      }
    });
    const result = res.data;
    log.warn('SetPlayerVolumeDb() - ', result);
    return result;
  } catch (error) {
    log.error('SetPlayerVolumeDb() - ', error);
    return {};
  }
}

export async function SetPlayerMute(id, mute) {
  try {
    const res = await API.get('/SetPlayerMute', {
      params: {
        playerId: id,
        mute: mute
      }
    });
    const result = res.data;
    log.warn('SetPlayerMute() - ', result);
    return result;
  } catch (error) {
    log.error('SetPlayerMute() - ', error);
    return {};
  }
}

export async function PlayerRemoveZone(id, zoneId) {
  try {
    const res = await API.get('/PlayerRemoveZone', {
      params: {
        playerId: id,
        zoneId: zoneId
      }
    });
    const result = res.data;
    log.warn('PlayerRemoveZone() - ', result);
    return result;
  } catch (error) {
    log.error('PlayerRemoveZone() - ', error);
    return {};
  }
}

export async function PlayerAddZone(id, zoneId) {
  try {
    const res = await API.get('/PlayerAddZone', {
      params: {
        playerId: id,
        zoneId: zoneId
      }
    });
    const result = res.data;
    log.warn('PlayerAddZone() - ', result);
    return result;
  } catch (error) {
    log.error('PlayerAddZone() - ', error);
    return {};
  }
}

export async function RenamePlayer(id, name) {
  try {
      const res = await API.get('/RenamePlayer', {
          params: {
              playerId: id,
              newPlayerName: name
          }
      });
      const result = res.data;
      log.warn('RenamePlayer() - ', result);
      return result;
  } catch (error) {
      log.error('RenamePlayer() - ', error);
      return {};
  }
}

export async function SetPlayerMode(id, mode) {
  try {
      const res = await API.get('/SetPlayerMode', {
          params: {
              playerId: id,
              mode: mode
          }
      });
      const result = res.data;
      log.warn('SetPlayerMode() - ', result);
      return result;
  } catch (error) {
      log.error('SetPlayerMode() - ', error);
      return {};
  }
}

export async function PlayerLoadPlaylistFromFile(id, playlistPath) {
  try {
    const res = await API.get('/PlayerLoadPlaylistFromFile', {
      params: {
        playerId: id,
        playlistPath: playlistPath
      }
    });
    const result = res.data;
    log.warn('PlayerLoadPlaylistFromFile() - ', result);
    return result;
  } catch (error) {
    log.error('PlayerLoadPlaylistFromFile() - ', error);
    return {};
  }
}

export async function PlayerPlayTrackByIndex(id, trackIndex) {
  try {
      const res = await API.get('/PlayerPlayTrackByIndex', {
          params: {
              playerId: id,
              trackIndex: trackIndex
          }
      });
      const result = res.data;
      log.warn('PlayerPlayTrackByIndex() - ', result);
      return result;
  } catch (error) {
      log.error('PlayerPlayTrackByIndex() - ', error);
      return {};
  }
}

export async function GetPlayerPlaylist(id) {
  try {
      const res = await API.get('/GetPlayerPlaylist', {
          params: {
              playerId: id,
          }
      });
      const result = res.data;
      log.warn('GetPlayerPlaylist() - ', result);
      return result;
  } catch (error) {
      log.error('GetPlayerPlaylist() - ', error);
      return {};
  }
}

export async function CreatePlayer(name, playerType) {
  try {
      const res = await API.get('/CreatePlayer', {
          params: {
            playerName: name,
            playerType: playerType
          }
      });
      const result = res.data;
      log.warn('CreatePlayer() - ', result);
      return result;
  } catch (error) {
      log.error('CreatePlayer() - ', error);
      return {};
  }
}

export async function DeletePlayer(id) {
  try {
      const res = await API.get('/DeletePlayer', {
          params: {
              playerId: id,
          }
      });
      const result = res.data;
      log.warn('DeletePlayer() - ', result);
      return result;
  } catch (error) {
      log.error('DeletePlayer() - ', error);
      return {};
  }
}
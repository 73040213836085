import actionTypes from './actions'

/**
 * Main reducer for global AppStore using "Redux styled" actions
 * @param {object} state - current/default state
 * @param {string} action.type - unique name of the action
 * @param {*} [action.payload] - optional data object or the function to get data object
 */
const AppReducer = (state, action) => {
  switch (action.type) {
    case actionTypes.global.SET_DARK_MODE:
      return {
        ...state,
        darkMode: action.payload,
      };
    case actionTypes.global.SET_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case actionTypes.global.SET_MENU_VISIBLE:
      return {
        ...state,
        menuVisible: action.payload,
      };
    case actionTypes.global.SET_ZONE_NAME_VISIBLE:
      return {
        ...state,
        zoneNameVisible: action.payload,
      };
    case actionTypes.global.SET_ZONES_VISIBLE:
      return {
        ...state,
        zonesVisible: action.payload,
      };

    //mzap
    case actionTypes.mzap.SET_PLAYERS:
      return {
        ...state,
        players: action.players,
      };

    case actionTypes.mzap.SET_ZONES:
      return {
        ...state,
        zones: action.zones,
      };

    case actionTypes.mzap.SET_PLAYLISTS:
      return {
        ...state,
        playlists: action.playlists,
      };

    case actionTypes.mzap.SET_BASE_URL:
      return {
        ...state,
        baseUrl: action.baseUrl,
      };

    case actionTypes.mzap.SET_RECENT_CONNECTIONS:
      return {
        ...state,
        recentConnections: action.recentConnections,
      };

    default:
      return state;
  }
};

export default AppReducer;

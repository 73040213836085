import API from '.';
import log from '../utils/log';

export async function GetZones() {
    try {
        const res = await API.get('/GetZones');
        const result = res.data;
        log.warn('GetZones() - ', result);
        return result;
    } catch (error) {
        log.error('GetZones() - ', error);
        return error;
    }
}

export async function SetZoneMute(id, mute) {
    try {
        const res = await API.get('/SetZoneMute', {
            params: {
                zoneId: id,
                mute: mute
            }
        });
        const result = res.data;
        log.warn('SetZoneMute() - ', result);
        return result;
    } catch (error) {
        log.error('SetZoneMute() - ', error);
        return {};
    }
}

export async function RenameZone(id, name) {
    try {
        const res = await API.get('/RenameZone', {
            params: {
                zoneId: id,
                newZoneName: name
            }
        });
        const result = res.data;
        log.warn('RenameZone() - ', result);
        return result;
    } catch (error) {
        log.error('RenameZone() - ', error);
        return {};
    }
}
import actionTypes from './actions';

//global
export const SetMenuVisible = (visibility) => {
  return {
    type: actionTypes.global.SET_MENU_VISIBLE,
    payload: visibility,
  }
}

//mzap
export const SetPlayers = (players) => {
  return {
    type: actionTypes.mzap.SET_PLAYERS,
    players: players,
  }
}

export const SetZones = (zones) => {
  return {
    type: actionTypes.mzap.SET_ZONES,
    zones: zones,
  }
}

export const SetBaseUrl = (baseUrl) => {
  return {
    type: actionTypes.mzap.SET_BASE_URL,
    baseUrl: baseUrl,
  }
}

export const SetRecentConnections = (recentConnections) => {
  return {
    type: actionTypes.mzap.SET_RECENT_CONNECTIONS,
    recentConnections: recentConnections,
  }
}

export const SetPlaylists = (playlists) => {
  return {
    type: actionTypes.mzap.SET_PLAYLISTS,
    playlists: playlists,
  }
}
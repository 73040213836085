import React from 'react';
// import HomeIcon from '@material-ui/icons/Home';
// import BuildIcon from '@material-ui/icons/Build';
// import InfoIcon from '@material-ui/icons/Info';

import SettingsIcon from '@material-ui/icons/Settings';
import SettingsEthernetIcon from '@material-ui/icons/SettingsEthernet';
import PlaylistPlayIcon from '@material-ui/icons/PlaylistPlay';
import QueueMusicIcon from '@material-ui/icons/QueueMusic';
import FeaturedPlayListIcon from '@material-ui/icons/FeaturedPlayList';
import { Players, Player, NotFound, Connect, Zones, Settings } from './views';

export const TITLE_PUBLIC = 'Multi Zone Audio Matrix ASIO Player';
export const TITLE_PRIVATE = 'Multi Zone Audio Matrix ASIO Player';
export const ROWS_PER_PAGE = 25; // Amount of rows per single page in tables and grids

/**
 * Single source of truth for Pages/Views of the App
 * Used for Routing, in SideBar and TopBar, and so on
 */
export const PAGES = [
  {
    // Default page mapping
    exact: true,
    // Don't set href for '/' !!! We use .href to find proper Title in TopBar component
    path: '/',
    component: Connect, // Change to most frequently used view
  },
  {
    showInSidebar: true,
    title: 'Connect',
    href: '/connect',
    path: '/connect',
    component: Connect,
    icon: <SettingsEthernetIcon />,
  },
  {
    showInSidebar: true,
    title: 'Players',
    href: '/players',
    path: '/players',
    component: Players,
    icon: <PlaylistPlayIcon />,
  },
  {
    showInSidebar: true,
    title: 'Zones',
    href: '/zones',
    path: '/zones',
    component: Zones,
    icon: <QueueMusicIcon />,
  },
  {
    showInSidebar: true,
    title: 'Settings',
    href: '/settings',
    path: '/settings',
    component: Settings,
    icon: <SettingsIcon />,
  },
  {
    showInSidebar: false,
    title: 'Player',
    href: '/player',
    path: ['/player/:id', '/player'],
    component: Player,
    icon: <FeaturedPlayListIcon />,
  },

  // {
  //   showInSidebar: true,
  //   title: 'Player',
  //   href: '/player',
  //   path: ['/player/:id', '/player'],
  //   component: Tools,
  //   icon: <BuildIcon />,
  // },
  {
    title: 'Page does not exist',
    component: NotFound,
  },
];

import React, { forwardRef, useCallback } from 'react';
//import InboxIcon from '@material-ui/icons/MoveToInbox';
//import MailIcon from '@material-ui/icons/Mail';
import {
    Button, List, ListItem, Switch, Divider, FormControlLabel,
    Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { NavLink } from 'react-router-dom';
import { useAppStore } from './../store/AppStore';
import actionTypes from './../store/actions';
import {localStorageSet } from '../utils/localStorage';

/**
 * Router link with styling to use as Items in SideBar
 */
const SideBarLink = forwardRef((props, ref) => {
    const classes = useStyles();
    return (
        <div ref={ref} className={classes.link}>
            <NavLink {...props} />
        </div>
    );
});

const useStyles = makeStyles((theme) => ({
    root: {},
    item: {
        display: 'flex',
        paddingTop: 0,
        paddingBottom: 0,
    },
    button: {
        // color: theme.palette.button,
        padding: '10px 8px',
        justifyContent: 'flex-start',
        textTransform: 'none',
        letterSpacing: 0,
        width: '100%',
        fontWeight: theme.typography.fontWeightMedium,
        flexGrow: 1,
    },
    iconOrMargin: {
        // color: theme.palette.icon,
        width: 24,
        height: 24,
        display: 'flex',
        alignItems: 'center',
        marginRight: theme.spacing(1),
    },
    active: {
        color: theme.palette.primary.main,
        fontWeight: theme.typography.fontWeightMedium,
    },
    link: { flexGrow: 1 },
    switchTheme: {
        display: 'flex',
        paddingTop: 0,
        paddingBottom: 0,
        marginLeft: theme.spacing(0.2),
    },
}));

export default function SideNav({ pages, showIcons = true }) {
    const classes = useStyles();
    const { state, dispatch } = useAppStore();

    const handleSwitchDarkMode = useCallback(
        () => {
            localStorageSet('darkMode', !state.darkMode)
            //localStorage.setItem('darkMode', JSON.stringify(!state.darkMode))
            dispatch({
                type: actionTypes.global.SET_DARK_MODE,
                payload: !state.darkMode,
            })
        },
        [state, dispatch]
    );

    const handleZonesVisibility = useCallback(
        () => {
            localStorageSet('zonesVisible', !state.zonesVisible)
            //localStorage.setItem('zonesVisible', JSON.stringify(!state.zonesVisible))
            dispatch({
                type: actionTypes.global.SET_ZONES_VISIBLE,
                payload: !state.zonesVisible,
            })
        },
        [state, dispatch]
    );

    const handleZoneNameVisibility = useCallback(
        () => {
            localStorageSet('zoneNameVisible', !state.zoneNameVisible)
            // localStorage.setItem('zoneNameVisible', JSON.stringify(!state.zoneNameVisible))
            dispatch({
                type: actionTypes.global.SET_ZONE_NAME_VISIBLE,
                payload: !state.zoneNameVisible,
            })
        },
        [state, dispatch]
    );

    return (
        <div>
            <List>
                {pages.map((page) => (
                    <ListItem key={`${page.title}-${page.href}`} className={classes.item} disableGutters>
                        <Button activeClassName={classes.active} className={classes.button}
                            component={SideBarLink} to={page.href}>
                            <div className={classes.iconOrMargin}>{showIcons ? page.icon : null}</div>
                            {page.title}
                        </Button>
                    </ListItem>
                ))}
            </List>
            <Divider />

            <FormControlLabel className={classes.switchTheme}
                control={
                    <Switch
                        color="primary"
                        title={state.darkMode ? 'Switch to Light mode' : 'Switch to Dark mode'}
                        checked={state.darkMode}
                        onChange={handleSwitchDarkMode}
                    />
                }
                label={<Typography variant="body2">
                    {state.darkMode ? 'Light mode' : 'Dark mode'}
                </Typography>}
            />

            <FormControlLabel className={classes.switchTheme}
                control={
                    <Switch
                        color="primary"
                        title={state.zonesVisible ? 'Hide zones' : 'Show zones'}
                        checked={state.zonesVisible}
                        onChange={handleZonesVisibility}
                    />
                }
                label={<Typography variant="body2">
                    {state.zonesVisible ? 'Hide zones' : 'Show zones'}
                </Typography>}
            />

            <FormControlLabel className={classes.switchTheme}
                control={
                    <Switch
                        color="primary"
                        title={state.zoneNameVisible ? 'Hide zone name' : 'Show zone name'}
                        checked={state.zoneNameVisible}
                        onChange={handleZoneNameVisibility}
                    />
                }
                label={<Typography variant="body2">
                    {state.zoneNameVisible ? 'Hide names' : 'Show names'}
                </Typography>}
            />

            {/* <List>
                {['All mail', 'Trash', 'Spam'].map((text, index) => (
                    <ListItem button key={text}>
                        <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
                        <ListItemText primary={text} />
                    </ListItem>
                ))}
            </List> */}
        </div>
    );
}
import React, { Component } from 'react';
import './App.css';
// import { ThemeProvider } from '@material-ui/core/styles';
// import { createMuiTheme } from '@material-ui/core/styles';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Layout from './views/Layout'
import { PAGES } from './consts';
import AppStore from './store/AppStore';
import AppThemeProvider from './theme';
import { setGlobalApi } from './api';
import axios from 'axios';
import { ErrorBoundary } from './components';
import {AppSnackBarProvider} from './components/AppSnackBar';
import log from 'loglevel';

class App extends Component {
  constructor(props) {
    super(props);
    // this.state = {
    //   token: null,
    //   currentUser: {},
    //};

    const baseUrl = localStorage.getItem('baseUrl')
    log.warn("baseUrl: ", baseUrl)
    // Create and configure API
    this.api = axios.create({
      // Use Local storage to override API base path
      baseURL: `${baseUrl || process.env.REACT_APP_API_URL}/`,
      //baseURL: `${localStorage.getItem('REACT_APP_API_URL') || process.env.REACT_APP_API_URL}/`,
    });
    // this.api.interceptors.response.use(
    //   (res) => res,
    //   (err) => {
    //     if (Number(err?.response?.status) === 401) {
    //       // Token expired, or hacked
    //       this.setToken(null);
    //     }
    //     return Promise.reject(err);
    //   }
    // );
    setGlobalApi(this.api); // Set shared API instance
  }

  render() {
    return (
      <AppStore>
        <AppThemeProvider>
          <div className="App">
            <AppSnackBarProvider>
              <BrowserRouter>
                <Layout>

                  <ErrorBoundary>
                    <Switch>
                      {PAGES.map((page) => (
                        <Route key={`${page.title}-${page.href}`} {...page} />
                      ))}
                    </Switch>
                  </ErrorBoundary>

                </Layout>
              </BrowserRouter>
            </AppSnackBarProvider>
          </div>
        </AppThemeProvider>
      </AppStore>
    );
  }
}

export default App;

const global = {
    SET_DARK_MODE: 'SET_DARK_MODE',
    SET_ERROR: 'SET_ERROR',
    SET_MENU_VISIBLE: 'SET_MENU_VISIBLE',
    SET_ZONE_NAME_VISIBLE: 'SET_ZONE_NAME_VISIBLE',
    SET_ZONES_VISIBLE: 'SET_ZONES_VISIBLE',

}

const mzap = {
    SET_BASE_URL: 'SET_BASE_URL',
    SET_LOGIN: 'SET_LOGIN',
    SET_PASSWORD: 'SET_PASSWORD',
    SET_RECENT_CONNECTIONS: 'SET_RECENT_CONNECTIONS',

    SET_PLAYERS: 'SET_PLAYERS',
    SET_ZONES: 'SET_ZONES',
    SET_PLAYLISTS: 'SET_PLAYLISTS'
}

export default {
    global,
    mzap
}
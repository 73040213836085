import API from '.';
import log from '../utils/log';

export async function SaveWorkspace() {
    try {
        const res = await API.get('/SaveWorkspace');
        const result = res.data;
        log.warn('SaveWorkspace() - ', result);
        return result;
    } catch (error) {
        log.error('SaveWorkspace() - ', error);
        return error;
    }
}

export async function LoadWorkspace(fileName) {
    try {
        const res = await API.get('/LoadWorkspace', {
            params: {
                fileName: fileName
            }
        });

        const result = res.data;
        log.warn('LoadWorkspace() - ', result);
        return result;
    } catch (error) {
        log.error('LoadWorkspace() - ', error);
        return error;
    }
}

export async function GetAllPlaylists() {
    try {
        const res = await API.get('/GetAllPlaylists');
        const result = res.data;
        log.warn('GetAllPlaylists() - ', result);
        return result;
    } catch (error) {
        log.error('GetAllPlaylists() - ', error);
        return error;
    }
}

export async function UpdatePlaylists() {
    try {
        const res = await API.get('/UpdatePlaylists');
        const result = res.data;
        log.warn('UpdatePlaylists() - ', result);
        return result;
    } catch (error) {
        log.error('UpdatePlaylists() - ', error);
        return error;
    }
}
/**
 * Note: Don't import/export all Views directly, use lazy loading!
 */
import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import { withSuspense } from '../components';
import NotFound from './NotFound';
//import NotImplementedView from './NotImplemented';

/**
 * Views/Pages with Lazy Loading
 */
const Connect = withSuspense(
    React.lazy(() => import('./Connect')),
    <LinearProgress />
);
const Players = withSuspense(
    React.lazy(() => import('./Players')),
    <LinearProgress />
);
const Zones = withSuspense(
    React.lazy(() => import('./Zones')),
    <LinearProgress />
);
const Settings = withSuspense(
    React.lazy(() => import('./Settings')),
    <LinearProgress />
);
const Player = withSuspense(
    React.lazy(() => import('./Player')),
    <LinearProgress />
);

export { NotFound, Connect, Players, Player, Zones, Settings };
